




























import { RequestModel } from '@/models/Request.model';
import requestsRepository from '@/repository/modules/requests.repository';
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableRowRequest from '../table-row-request.vue';

@Component({
  components: {
    TableRowRequest,
  },
})
export default class RelatedRquestComponent extends Vue {
  @Prop({ required: true })
  request_id!: string;

  requestsRelated: RequestModel[] = [];

  loading = true;
  total_results = 0;

  async created() {
    const requests = await requestsRepository.findDataRequestRelated(this.request_id);
    this.requestsRelated = requests;
    this.total_results = requests.length;
    this.loading = false;
  }
}
