




































import { EmailModel } from '@/models/Emails.model';
import requestsRepository from '@/repository/modules/requests.repository';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { setterDate } from '@/plugins/utils';

@Component
export default class EmailHistoryComponent extends Vue {
  @Prop({ required: true })
  request_id!: string;

  requestsEmails: EmailModel[] = [];

  loading = true;
  total_results = 0;

  async created() {
    const emails = await requestsRepository.findEmailsRequestRelated(this.request_id);
    this.requestsEmails = emails;
    this.total_results = emails.length;
    this.loading = false;
  }

  setterDate = setterDate;
}
