













































import { RequestModel } from '@/models/Request.model';
import requestsRepository from '@/repository/modules/requests.repository';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class OptionsRequestNotaSimple extends Vue {
  @Prop({ required: true, type: RequestModel })
  dataRequest!: RequestModel;

  registro_id = '';
  notes = '';

  loadingButtonRegistroId = false;
  loadingButtonNotes = false;
  loadingButtonFinished = false;
  loadingButtonNotificationInit = false;
  loadingButtonNotificationDeclined = false;

  fileNotaSimple: File | null = null;

  @Watch('_dataRequest', { immediate: true })
  updatedDataRequest() {
    console.log(this.dataRequest);
    this.registro_id = this.dataRequest.request_id_registro as string;
    this.notes = this.dataRequest.request_extra_data as string;
  }

  @Watch('_dataRequest')
  updatedDataRequest2() {
    console.log(this.dataRequest);
    this.registro_id = this.dataRequest.request_id_registro as string;
    this.notes = this.dataRequest.request_extra_data as string;
  }

  get _dataRequest() {
    return this.dataRequest.request_id_registro;
  }

  async uploadFile(data: File) {
    if (data) this.fileNotaSimple = data;
    else this.fileNotaSimple = null;
  }

  async addRegistroID() {
    if (this.registro_id) {
      this.loadingButtonRegistroId = true;
      await requestsRepository.updateRegistroID(this.dataRequest._id, this.registro_id).then(() => {
        this.$emit('ReloadRequest');
      });
      this.loadingButtonRegistroId = false;
    }
  }
  async addNotes() {
    if (this.notes) {
      this.loadingButtonNotes = true;
      await requestsRepository.updateNote(this.dataRequest._id, this.notes).then(() => {
        this.$emit('ReloadRequest');
      });
      this.loadingButtonNotes = false;
    }
  }
  async setStateToFinish() {
    if (this.registro_id) {
      this.loadingButtonFinished = true;
      await requestsRepository.updateStateToFinish(this.dataRequest._id).then(() => {
        this.$emit('ReloadRequest');
      });
      this.loadingButtonFinished = false;
    }
  }

  async setNotificationInit() {
    if (this.registro_id) {
      this.loadingButtonNotificationInit = true;
      await requestsRepository.notificationRequestInitProcess(this.dataRequest._id).then(() => {
        this.$emit('ReloadRequest');
      });
      this.loadingButtonNotificationInit = false;
    }
  }
  async setNotificationDeclined() {
    this.loadingButtonNotificationDeclined = true;
    await requestsRepository.notificationRequestDeclinedProcess(this.dataRequest._id).then(() => {
      this.$emit('ReloadRequest');
    });
    this.loadingButtonNotificationDeclined = false;
  }

  async completedNotaSimpleWithFile() {
    if (this.fileNotaSimple) {
      const form = new FormData();
      form.append('file', this.fileNotaSimple);
      await requestsRepository.notificationSendFile(this.dataRequest._id, form);
      this.$emit('ReloadRequest');
    }
  }

  get ExistsIdRegistro() {
    return !!this.dataRequest.request_id_registro;
  }
}
