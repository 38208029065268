






























import { RequestModel } from '@/models/Request.model';
import { copyToClipboard, setterDate } from '@/plugins/utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CustomersRepository from '@/repository/modules/customers.repository';

@Component
export default class InfoRequestNotaSimple extends Vue {
  @Prop({ required: true, type: RequestModel })
  dataRequest!: RequestModel;

  customer_id: string | null = null;
  customer_email = '--';

  request_info = {
    formName: '',
    formAddressLocalidad: '',
    formAddressName: '',
    formFast4H: false,
  };

  @Watch('dataRequest', { immediate: true })
  updatedDataRequest() {
    this.customer_id = this.dataRequest.user_id;
    this.getCustomer();
    this.setRequestInfo(this.dataRequest.request_data);
  }

  setRequestInfo(infoDataRequest: string) {
    const infoFormated = JSON.parse(infoDataRequest);
    this.request_info.formName = infoFormated.nombre_propietario;
    this.request_info.formAddressLocalidad = infoFormated.inmueble_municipio;
    this.request_info.formAddressName = infoFormated.inmueble_direccion;
    this.request_info.formFast4H = infoFormated.fast_4horas;
  }

  async getCustomer() {
    const { customer } = await CustomersRepository.GetCustomerData(this.customer_id as string);
    this.customer_email = customer.user_email;
  }

  setterDate = setterDate;

  copyToClipboard = copyToClipboard;
}
