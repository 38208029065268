




































import { RequestModel } from '@/models/Request.model';
import requestsRepository from '@/repository/modules/requests.repository';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DialogNewNotaSimple from './dialog-new-nota-simple.vue';

@Component({
  components: {
    DialogNewNotaSimple,
  },
})
export default class OptionsRequestRegistro extends Vue {
  @Prop({ required: true, type: RequestModel })
  dataRequest!: RequestModel;

  registro_id = '';
  notes = '';

  loadingButtonRegistroId = false;
  loadingButtonNotes = false;
  loadingButtonFinished = false;
  loadingButtonNotificationInit = false;
  loadingButtonNotificationFondos = false;

  showDialogNewNotaSimple = false;

  @Watch('_dataRequest', { immediate: true })
  updatedDataRequest() {
    console.log(this.dataRequest);
    this.registro_id = this.dataRequest.request_id_registro as string;
    this.notes = this.dataRequest.request_extra_data as string;
  }

  get _dataRequest() {
    return this.dataRequest.request_id_registro;
  }

  async addRegistroID() {
    if (this.registro_id) {
      this.loadingButtonRegistroId = true;
      await requestsRepository.updateRegistroID(this.dataRequest._id, this.registro_id).then(() => {
        this.$emit('ReloadRequest');
      });
      this.loadingButtonRegistroId = false;
    }
  }
  async addNotes() {
    if (this.notes) {
      this.loadingButtonNotes = true;
      await requestsRepository.updateNote(this.dataRequest._id, this.notes).then(() => {
        this.$emit('ReloadRequest');
      });
      this.loadingButtonNotes = false;
    }
  }
  async setStateToFinish() {
    if (this.registro_id) {
      this.loadingButtonFinished = true;
      await requestsRepository.updateStateToFinish(this.dataRequest._id).then(() => {
        this.$emit('ReloadRequest');
      });
      this.loadingButtonFinished = false;
    }
  }

  async setNotificationInit() {
    if (this.registro_id) {
      this.loadingButtonNotificationInit = true;
      await requestsRepository.notificationRequestInitProcess(this.dataRequest._id).then(() => {
        this.$emit('ReloadRequest');
      });
      this.loadingButtonNotificationInit = false;
    }
  }

  async setNotificationFondos() {
    this.loadingButtonNotificationFondos = true;
    await requestsRepository.notificationRequestFondosProcess(this.dataRequest._id).then(() => {
      this.$emit('ReloadRequest');
    });
    this.loadingButtonNotificationFondos = false;
  }

  get ExistsIdRegistro() {
    return !!this.dataRequest.request_id_registro;
  }
}
