

































import { RequestModel } from '@/models/Request.model';
import { copyToClipboard, setterDate } from '@/plugins/utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CustomersRepository from '@/repository/modules/customers.repository';

@Component
export default class InfoRequestRegistro extends Vue {
  @Prop({ required: true, type: RequestModel })
  dataRequest!: RequestModel;

  customer_id: string | null = null;
  customer_email = '--';

  request_info = {
    cantidad_propiedades: '',
    nif_dni_registro: '',
    nombre_registro: '',
    tipo_empresa_persona: '',
  };

  @Watch('dataRequest', { immediate: true })
  updatedDataRequest() {
    this.customer_id = this.dataRequest.user_id;
    this.getCustomer();
    this.setRequestInfo(this.dataRequest.request_data);
  }

  setRequestInfo(infoDataRequest: string) {
    const infoFormated = JSON.parse(infoDataRequest);
    console.log(infoFormated);
    this.request_info.cantidad_propiedades = infoFormated.cantidad_propiedades;
    this.request_info.nif_dni_registro = infoFormated.nif_dni_registro;
    this.request_info.nombre_registro = infoFormated.nombre_registro;
    this.request_info.tipo_empresa_persona = infoFormated.tipo_empresa_persona;
  }

  async getCustomer() {
    const { customer } = await CustomersRepository.GetCustomerData(this.customer_id as string);
    this.customer_email = customer.user_email;
  }

  setterDate = setterDate;

  copyToClipboard = copyToClipboard;
}
