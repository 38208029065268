





















import requestsRepository from '@/repository/modules/requests.repository';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class DialogNewNotaSimple extends Vue {
  @Prop({ required: true, type: String })
  registro_propiedad_id!: string;
  @Prop({ required: true, type: Boolean, default: false })
  showAlert!: boolean;

  isLoading = false;

  isFormCompleted = false;

  existsError = '';

  registro_id = '';

  @Watch('registro_id')
  checkRegistroId() {
    console.log(this.registro_id);
    if (this.registro_id != '' && this.registro_id.length > 3) this.isFormCompleted = true;
    else this.isFormCompleted = false;
  }

  async payAndCreateNotaSimple() {
    this.isLoading = true;
    this.existsError = '';
    const { payment_status } = await requestsRepository.createNotaSimpleInRegistroPropiedades(
      this.registro_propiedad_id,
      this.registro_id,
    );
    this.isLoading = false;
    if (payment_status == 'succeeded') {
      this.$emit('ReloadRequest');
    } else {
      this.existsError = 'Payment not processed..';
    }
  }
}
